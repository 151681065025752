body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.text-blue{color: #194775;}
a.navbar-brand img {max-width: 150px;}
.nav-item.active a{color: #e62730 !important;}
.nav-item a{color: #194775 !important;font-weight: 500;font-size: 20px;}
.nav-item a:hover{color: #e62730 !important;}
.sec-padd{padding:60px 0};
.sec-padd-top{padding-top:60px};
.sec-padd-bot{padding-bottom:60px};

.con-icon .MuiSvgIcon-root{color: #194775;}
.con-text a{color: #194775;font-weight: 600;}
.con-text a:hover{color: #e62730}
.con-box {display: flex;}
.con-icon {margin-right: 7px;margin-top: 3px;}
.footer-wrapper {background: #6684a3;}
.form-wrapper {padding: 15px;box-shadow: 1px 2px 8px 5px #888888;}
section.think-big {position: relative;-webkit-box-shadow: -4px 11px 21px #7889b6;box-shadow: -4px 11px 21px #7889b6;background-color: #325add;background-image: radial-gradient(circle 920px at center,#286dec 0,#286dec 24%,#2767e7 32%,#244dd2 66%,#244dd2 91%);z-index: 2;}
.background-text {position: absolute;top: 0;left: 0;right: 0;z-index: -1;color: rgba(0,11,50,.07);margin: 0 auto;font-size: 13em;text-align: center;text-transform: uppercase;}
.section-title{position: relative;display: inline-block;}
.section-title::after {position: absolute;content: '';background: url(./images/underline.png);width: 100%;height: 20px;left: 0;background-repeat: no-repeat;background-size: contain;bottom: 0;z-index: -1;}
.p-relative{position: relative;}
.client-section{background: url(./images/client-background.jpg);background-repeat: no-repeat;background-size: cover;z-index: -2;}
.social-media .MuiSvgIcon-root {color: #194775;font-size: 40px;margin-right: 10px;padding: 6px;border: 1px solid #194775;border-radius: 50%;}
.social-media .MuiSvgIcon-root:hover{color: #e62730;border: 1px solid #e62730;}
@media only screen and (max-width:1200px) {
  
}
@media only screen and (max-width:992px) {
  .sec-padd{padding:30px 0 !important};
  .sec-padd-top{padding-top:30px !important};
  .sec-padd-bot{padding-bottom:30px !important};
  #navbarSupportedContent {background: #f5f5f5;padding: 0 10px;}
  .nav-item {border-bottom: 1px solid #b2b2b2;}
  .nav-item:last-child {border-bottom: 0;}
  .nav-item a {font-size: 16px;}
  .section-title {font-size: 22px;}
  .h4, h4{font-size: 1.2rem !important;}
  .h5, h5{font-size: 1rem !important;}
  .h6, h6 {font-size: 0.9rem !important;}
}
@media only screen and (max-width:767px) {
  .background-text{font-size: 3.2rem}
  .styles_scroll-to-top__2A70v { display: none;}
}
@media only screen and (max-width:576px) {
  
}