h1, h2, h3, h4, h5,h6 {
  font-family: Montserrat,Helvetica,Arial,sans-serif;
  font-weight: 600 !important;
  line-height: 1.4 !important;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.text-blue{color: #194775;}
a.navbar-brand img {max-width: 150px;}
.nav-item.active a{color: #e62730 !important;font-size: 20px;}
.nav-item a{color: #194775 !important;font-weight: 500;}
.nav-item a:hover{color: #e62730 !important;}
.sec-padd{padding:60px 0};
.sec-padd-top{padding-top:60px};
.sec-padd-bot{padding-bottom:60px};

.con-icon .MuiSvgIcon-root{color: #194775;}
.con-text a{color: #194775;font-weight: 600;}
.con-text a:hover{color: #e62730}